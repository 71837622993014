var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"a-booking-nav"},[_c('div',{staticClass:"a-booking-nav__header"},[_c('div',{staticClass:"a-booking-nav__header__container"},[_vm._l((_vm.updateItems.slice(0, _vm.count)),function(item,index){return _c('router-link',{key:index,staticClass:"a-booking-nav__header-item",class:{ disabled: !item.link },attrs:{"to":item.link ? _vm.$i18nRoute({ name: item.link }) : '#',"exact":""}},[_c('span',{staticClass:"a-booking-nav__header-item-content"},[(_vm.mobile)?[_c('v-icon',{class:{
                stroke:
                  item.name == 'flights' || item.name == 'charter-flight',
              },attrs:{"icon":'navigation/' + item.name,"size":"24"}})]:[_vm._v(" "+_vm._s(_vm.$t(("account.booking.tabs.title." + (item.name))))+" ")]],2)])}),(_vm.updateItems.length > _vm.count)?[_c('div',{staticClass:"a-booking-nav__header-dropdown",class:{
            'router-link-active': _vm.updateItems
              .slice(_vm.count)
              .find(function (el) { return el.link === _vm.$route.name; }),
          }},[_c('span',{staticClass:"a-booking-nav__header-item-content"},[_vm._v(" "+_vm._s(_vm.$t('account.booking.tabs.title.more'))+" ")]),_c('div',{staticClass:"a-booking-nav__header-dropdown-list"},[_c('div',{staticClass:"a-booking-nav__header-dropdown-item default"},[_vm._v(" "+_vm._s(_vm.$t('account.booking.tabs.title.more'))+" ")]),_vm._l((_vm.updateItems.slice(_vm.count)),function(item,index){return _c('router-link',{key:index,staticClass:"a-booking-nav__header-dropdown-item",class:{ disabled: !item.link },attrs:{"to":item.link ? _vm.$i18nRoute({ name: item.link }) : '#',"exact":""}},[_vm._v(" "+_vm._s(_vm.$t(("account.booking.tabs.title." + (item.name))))+" ")])})],2)])]:_vm._e()],2)]),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }